import React, {Component} from 'react';
import {Card, Col, Container, Row} from 'react-bootstrap';
import {useNavigate, useParams} from "react-router-dom";
import axios from 'axios';
import ImageLoader from "./ImageLoader";
import Loading from "./Loading";

class Products extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            isLoading: true,
        };
    }

    detailProduct(kriteria, slug, slugproduct) {
        this.props.navigate(`/kriteria/${kriteria}/${slug}/${slugproduct}`);
    }

    componentDidMount() {
        axios.get(`api/produk/${this.props.params.kriteria}/${this.props.params.slug}`)
            .then(res => {
                const data = res.data;
                this.setState({
                    data: data
                });
            })
            .catch(console.error)
    }

    render() {
        document.title = `Bank AKP - ${this.props.params.slug}`;
        return (
            <>
                <section>
                    <Container>
                        <h1 className='display-6 text-capitalize'>{this.props.params.slug.replace(/-/g, ' ')}</h1>
                        <Row>
                            {this.state.isLoading ? (
                                <Loading/>
                            ) : (
                                this.state.data.map((data, i) => (
                                    <Col md="3" className="mb-3">
                                        <a href={`/kriteria/${this.props.params.kriteria}/${this.props.params.slug}/${data.slug}`}
                                           className='link-custom'>
                                            <Card className="text-dark shadow-sm p-0">
                                                <ImageLoader className={' card-fit'}
                                                             path={`https://is3.cloudhost.id/akp-storage/produk/${data.image}`}
                                                             altName={data.image}/>
                                                <Card.Body>
                                                    <Card.Title className='text-dark'>{data.nama_produk}</Card.Title>
                                                    <Card.Text className='text-muted'>
                                                        {data.subtitle}
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </a>
                                    </Col>
                                )))}
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

function WithNavigate(props) {
    let params = useParams();
    let navigate = useNavigate();
    return <Products {...props} params={params} navigate={navigate}/>
}


export default WithNavigate;