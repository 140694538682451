import React, {Component} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {Call, Email, WhatsApp, Facebook, Instagram, YouTube, Twitter,} from '@mui/icons-material';
import {useParams, useNavigate} from "react-router-dom";
import axios from 'axios';

class FooterBottom extends Component {

    state = {
        data: []
    }

    componentDidMount() {
        axios.get(`api/info`)
            .then(res => {
                const data = res.data;
                this.setState({data: data});
            })
            .catch(console.error)
    }

    direct(url) {
        window.location = url;
    }


    render() {
        return (
            <div>
                <section className='footer text-dark sticky-bottom' style={{backgroundColor: '#ffffff'}}>
                    <br/>
                    <Container>
                        <Row className="justify-content-md-center">
                            <Col md={5}>
                                <h5><b>Kantor Pusat</b></h5>
                                <p className="text-start">
                                    {this.state.data.address}
                                </p>
                            </Col>
                            <Col md={4}>
                                <h5><b>Hubungi Kami</b></h5>
                                <ul className="list-unstyled">
                                    <li className='mb-2'><Call className='icon icon-socmed text-secondary'/> {this.state.data.phone}</li>
                                    <li className='mb-2'><Email className='icon icon-socmed text-secondary'/> {this.state.data.email}</li>
                                    <li className='mb-2'><WhatsApp className='icon icon-socmed text-secondary'/> {this.state.data.whatsaap}</li>
                                </ul>
                            </Col>
                            <Col md={3} className='mb-3'>
                                <h5><b>Sosial Media</b></h5>
                                <Row className="justify-content-md-center">

                                    <Col xs md>
                                        <a href={`https://www.facebook.com/${this.state.data.facebook}`}
                                           className='link-custom link-socmed text-secondary'><Facebook/></a>
                                    </Col>
                                    <Col xs md>
                                        <a href={`https://www.instagram.com/${this.state.data.instagram}`} className='link-custom link-socmed text-secondary'><Instagram/></a>
                                    </Col>
                                    <Col xs md>
                                        <a href={`https://www.youtube.com/${this.state.data.youtube}`} className='link-custom link-socmed text-secondary'><YouTube/></a>
                                    </Col>
                                    <Col xs md>
                                        <a href={`https://www.twitter.com/${this.state.data.twitter}`} className='link-custom link-socmed text-secondary'><Twitter/></a>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className='footer text-dark p-1 sticky-bottom' style={{backgroundColor: '#ffffff'}}>
                    <Container>
                        <Row>
                            <Col md={8} sm={12} xs={12} style={{fontSize: '10pt'}} className="mb-3">
                                © 2003 PT BPR Artha Karya Perdana
                            </Col>
                            <Col md={4} sm={12} xs={12}>
                                <Row>
                                    <Col xs={3} md={3} className="mb-3">
                                        <img src="https://is3.cloudhost.id/akp-storage/assets/logo-lps.png" alt="LPS" width={80}/>
                                    </Col>
                                    <Col xs={3} md={3} className="mb-3">
                                        <img src="https://is3.cloudhost.id/akp-storage/assets/BI_Logo.png" alt="bi" width={80}/>
                                    </Col>
                                    <Col xs={3} md={3} className="mb-3">
                                        <img src="https://is3.cloudhost.id/akp-storage/assets/Logo-BPR-Bersama-REVISI-850x1164.png" alt="bpr" width={25}/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        )
    }
}

function WithNavigate(props) {
    let params = useParams();
    let navigate = useNavigate();
    return <FooterBottom {...props} params={params} navigate={navigate}/>
}


export default WithNavigate;