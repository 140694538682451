import React, {Component} from 'react';
import {Accordion, Col, Container, Row} from 'react-bootstrap';
import {ArrowRight} from '@mui/icons-material';
import RelatedProduct from './RelatedProduct';
import {useNavigate, useParams} from "react-router-dom";
import axios from 'axios';
import parse from 'html-react-parser';

class Product extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            informasi_produk: '',
            informasi_persyaratan: '',
            informasi_kontak: '',
            feature: [],
            defaultActiveKeys: ['0', '1', '2'],
            isLoading: true,
        };
    }


    componentDidMount() {
        axios.get(`api/produk/${this.props.params.slugproduct}`)
            .then(res => {
                const data = res.data;
                this.setState({
                    data: data.produk,
                    feature: data.feature,
                    informasi_produk: data.produk.informasi_produk,
                    informasi_persyaratan: data.produk.informasi_persyaratan,
                    informasi_kontak: data.produk.informasi_kontak,
                });
            })
            .catch(console.error)
    }

    direct(url) {
        window.location.pathname = url;
    }

    onChange = (keys) => {
        this.setState({
            defaultActiveKeys: keys
        });

    };


    render() {
        document.title = `Bank AKP - ${this.state.data.nama_produk}`;
        return (
            <>
                <section>
                    <Container>
                        <h1 className="display-5 fw-bold">{this.state.data.nama_produk}</h1>
                        <p>{this.state.data.subtitle}</p>

                        <h3 className='mb-3'>Informasi</h3>
                        <Accordion defaultActiveKey={this.state.defaultActiveKeys} onChange={this.onChange} flush>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Kenali Produk</Accordion.Header>
                                <Accordion.Body>
                                    {parse(this.state.informasi_produk)}
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Persyaratan</Accordion.Header>
                                <Accordion.Body>
                                    {parse(this.state.informasi_persyaratan)}
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Hubungi Kami </Accordion.Header>
                                <Accordion.Body>
                                    {parse(this.state.informasi_kontak)}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <br/>
                    </Container>
                </section>

                <section>
                    <Container>
                        <Row>
                            <Col className='mt-3'><h4><b>Produk Terkait</b></h4></Col>
                            <Col className='mt-3'>
                                <a href={`/kriteria/${this.props.params.kriteria}/${this.props.params.slug}`}
                                   className='link-custom float-end text-primary'>Semua Produk <ArrowRight/></a>
                            </Col>
                        </Row>
                        <RelatedProduct/>
                    </Container>
                </section>
            </>
        )
    }
}

function WithNavigate(props) {
    let params = useParams();
    let navigate = useNavigate();
    return <Product {...props} params={params} navigate={navigate}/>
}


export default WithNavigate;