import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { ArrowRight } from '@mui/icons-material';
import Promo from './Promo'
export default class Page404 extends Component {

    state = {
        data: [],
        desc: ''
    }

    render() {
        document.title = `Bank AKP - Tentang Kami`;
        return (
            <div>
                <section>
                    <div className="text-center mb-5">
                        Page not found
                    </div>
                </section>
                
                <section className='bg-light'>
                    <Container>
                        <Row>
                            <Col className='mt-3 text-mute'><h4><b>Promo</b></h4></Col>
                            <Col className='mt-3'>
                                <a href="/promo" className='link-custom float-end text-primary'>Semua Promo <ArrowRight /></a>
                            </Col>
                        </Row>
                        <Promo />
                    </Container>
                </section>
            </div>
            )
    }
}
