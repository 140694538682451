import React, {Component} from "react";
import {
    Container,
    Carousel,
    Row,
    Col,
    Card,
} from "react-bootstrap";
import {ArrowRight} from "@mui/icons-material";
import {useParams, useNavigate} from "react-router-dom";
import Promo from "./Promo";
import parse from "html-react-parser";
import axios from "axios";
import ReactTimeAgo from "react-time-ago";
import ImageLoader from "./ImageLoader";
import Loading from "./Loading";
import CardLoading from "./CardLoading";

class Home extends Component {
    state = {
        isBannersLoading: true,
        isNewsLoading: true,
        data: [],
        news: [],
    };

    async componentDidMount() {
        await axios
            .get(`api/banner`)
            .then((res) => {
                const data = res.data;
                this.setState({data: data, isBannersLoading: false});
            })
            .catch(console.error);

        axios
            .get(`api/news`)
            .then((res) => {
                const data = res.data;
                this.setState({news: data, isNewsLoading: false});
            })
            .catch(console.error);
    }

    detailNews(slug) {
        this.props.navigate(`/news/${slug}`);
    }

    direct(url) {
        window.location.pathname = url;
    }

    render() {
        document.title = `Bank AKP - PT. BPR ARTHA KARYA PERDANA`;
        return <>
            <section className="bg-light">
                <Container>
                    {this.state.isBannersLoading ? (
                        <Loading/>
                    ) : (
                        <Carousel>
                            {this.state.data.map((data, i) => (
                                <Carousel.Item>
                                    <a href={data.url}>
                                        <ImageLoader
                                            className={"d-block w-100 mt-3"}
                                            path={`https://is3.cloudhost.id/akp-storage/banners/${data.banner}`}
                                            altName={data.banner}
                                        />
                                    </a>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    )}
                </Container>
            </section>

            <section className="bg-light">
                <Container>
                    <Row>
                        <Col className="mt-3 text-mute">
                            <h4>
                                <b>Promo</b>
                            </h4>
                        </Col>
                        <Col className="mt-3">
                            <a href="/promo" className="link-custom float-end text-primary">
                                Semua Promo <ArrowRight/>
                            </a>
                        </Col>
                        <Promo/>
                    </Row>
                </Container>
            </section>

            <section className="bg-light">
                <Container>
                    <Row>
                        <Col className="mt-3 text-mute">
                            <h4>
                                <b>Berita</b>
                            </h4>
                        </Col>
                        <Col className="mt-3">
                            <a href="/news" className="link-custom float-end text-primary">
                                Semua Berita <ArrowRight/>
                            </a>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className="mb-3">
                            <Row>
                                {this.state.isNewsLoading ? (
                                    <>
                                        <CardLoading/>
                                        <CardLoading/>
                                        <CardLoading/>
                                        <CardLoading/>
                                    </>
                                ) : (
                                    this.state.news.slice(0, 4).map((data, i) => (
                                        <Col md={6}>
                                            <a href={`/news/${data.slug}`} className="link-custom">
                                                <Card className="mb-3">
                                                    <Row className="g-0">
                                                        <Col md={6}>
                                                            <img
                                                                className="image-fit rounded-start"
                                                                src={`https://is3.cloudhost.id/akp-storage/news/${data.image}`}
                                                                alt="news"
                                                            />
                                                        </Col>
                                                        <Col md={6}>
                                                            <Card.Body>
                                                                <Card.Title>{data.title}</Card.Title>
                                                                <Card.Text>
                                                                    {parse(data.description.substring(0, 40))}
                                                                </Card.Text>
                                                                <small className="text-muted">
                                                                    Last updated{" "}
                                                                    <ReactTimeAgo
                                                                        date={data.created_at}
                                                                        locale="en-US"
                                                                    />
                                                                </small>
                                                            </Card.Body>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </a>
                                        </Col>
                                    ))
                                )}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    }
}

function WithNavigate(props) {
    let params = useParams();
    let navigate = useNavigate();
    return <Home {...props} params={params} navigate={navigate}/>;
}

export default WithNavigate;
