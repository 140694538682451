import React, {Component} from "react";
import {Container, Nav, Navbar} from "react-bootstrap";
import {Link, useNavigate, useParams} from "react-router-dom";
import axios from "axios";

class ResponsiveAppBar extends Component {
    state = {
        data: [],
    };

    componentDidMount() {
        axios
            .get(`api/info`)
            .then((res) => {
                const data = res.data;
                this.setState({data: data});
            })
            .catch(console.error);
    }

    render() {
        return (
            <>
                <Navbar
                    collapseOnSelect
                    expand="sm"
                    bg="light"
                    variant="light"
                    className="shadow shadow-sm p-2 mb-0 bg-body rounded">
                    <Container>
                        <Navbar.Brand>
                            <Link to="/" className="link">
                                <img
                                    src={`https://is3.cloudhost.id/akp-storage/assets/info/logo_large.png`}
                                    style={{width: "200px"}}
                                    alt="logo"
                                />
                            </Link>
                        </Navbar.Brand>
                        <Navbar.Toggle
                            aria-controls="responsive-navbar-nav"
                            style={{fontSize: "6pt"}}
                        />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto"></Nav>
                            <Nav className="justify-content-end">
                                <a
                                    href="/kriteria/perorangan"
                                    className="nav-link"
                                    style={{cursor: "pointer"}}>
                                    Perorangan
                                </a>
                                <a
                                    href="/kriteria/bisnis"
                                    className="nav-link"
                                    style={{cursor: "pointer"}}>
                                    Bisnis
                                </a>
                                <a
                                    href="/tentang-kami"
                                    className="nav-link"
                                    style={{cursor: "pointer"}}>
                                    Tentang Kami
                                </a>
                                <a
                                    href="/karir"
                                    className="nav-link"
                                    style={{cursor: "pointer"}}>
                                    Karir
                                </a>

                                <a
                                    href="https://eform.bankakp.co.id"
                                    className="nav-link"
                                    style={{cursor: "pointer"}}>
                                    Pengajuan Kredit
                                </a>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </>
        );
    }
}

function WithNavigate(props) {
    let params = useParams();
    let navigate = useNavigate();
    return <ResponsiveAppBar {...props} params={params} navigate={navigate}/>;
}

export default WithNavigate;
