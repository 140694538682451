import React, {useEffect, useState} from 'react';
import {Card} from 'react-bootstrap';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ReactTimeAgo from 'react-time-ago';
import ImageLoader from "./ImageLoader";
import Loading from "./Loading";

export default function Promo() {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const responsive = {
        superLargeDesktop: {
            breakpoint: {max: 4000, min: 3000},
            items: 5
        },
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 3
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 2
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 1
        }
    };

    function getData() {
        return fetch(`https://www.bumicms.bankakp.co.id/api/promos`)
            .then(response => response.json());
    }

    useEffect(() => {
        let mounted = true;
        getData()
            .then(items => {
                if (mounted) {
                    setData(items);
                    setIsLoading(false); // Set loading to false after data is fetched
                }
            });
        return () => mounted = false;
    }, []);

    return (
        <>
            {isLoading ? (
                <Loading/>
            ) : (
                <Carousel
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlaySpeed={500}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={300}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet"]}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-5-px"
                >
                    {data.map((data, i) => (
                        <div key={i} style={{marginBottom: '5px', marginRight: '10px'}}>
                            <a href={`/promo/${data.slug}`} className='link-custom'>
                                <Card className='mb-3'>
                                    <ImageLoader
                                        className={'card-img-top card-fit'}
                                        path={`https://is3.cloudhost.id/akp-storage/promos/thumbnails/${data.image}`}
                                        altName={data.image}
                                    />
                                    <Card.Body>
                                        <Card.Title>{data.title}</Card.Title>
                                        <small className="text-muted">
                                            Last updated <ReactTimeAgo date={data.created_at} locale="en-US"/>
                                        </small>
                                    </Card.Body>
                                </Card>
                            </a>
                        </div>
                    ))}
                </Carousel>
            )}
        </>
    );
}
