import React from "react";
import { Row, Col, Card, Placeholder } from "react-bootstrap";
export default function CardLoading() {
  return (
      <Col md={6}>
        <Card className="mb-3">
          <Row className="g-0">
            <Col md={6}>
              <img
                  className="image-fit-placeholder rounded-start"
                  src={`https://dummyimage.com/600x400/ffffff/383438&text=loading...`}
                  alt="news"
              />
            </Col>
            <Col md={6}>
              <Card.Body>
                <Placeholder as={Card.Title} animation="glow">
                  <Placeholder xs={4} />
                </Placeholder>
                <Placeholder as={Card.Text} animation="glow">
                  <Placeholder xs={7} /> <Placeholder xs={4} />{" "}
                  <Placeholder xs={4} /> <Placeholder xs={6} />{" "}
                  <Placeholder xs={8} />
                </Placeholder>
                <Placeholder variant="primary" xs={6} animation="glow" />
              </Card.Body>
            </Col>
          </Row>
        </Card>
      </Col>
  );
}
