import React, {Component} from 'react'
import {Card, Col, Container, Row} from 'react-bootstrap';
import {useNavigate, useParams} from "react-router-dom";
import axios from 'axios';
import ImageLoader from "./ImageLoader";
import CardLoading from "./CardLoading";

class KriteriaProduk extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            isLoading: true,
        };
    }

    componentDidMount() {
        axios.get(`api/kriteria/${this.props.params.kriteria}`)
            .then(res => {
                const data = res.data;
                this.setState({
                    data: data,
                    isLoading: false
                });
            })
            .catch(console.error)
    }

    detailProduct(kriteria, slug) {
        this.props.navigate(`/kriteria/${kriteria}/${slug}`);
    }

    render() {
        if (this.state.isLoading)
            return (
                <section>
                    <Container>
                        <h1 className='display-6 text-capitalize'>Loading..</h1>
                        <Row>
                            <CardLoading/>
                            <CardLoading/>
                            <CardLoading/>
                            <CardLoading/>
                        </Row>
                    </Container>
                </section>
            );
        document.title = `Bank AKP - ${this.props.params.kriteria}`;
        return (
            <section>
                <Container>
                    <h1 className='display-6 text-capitalize'>{this.props.params.kriteria}</h1>
                    <Row>
                        {this.state.data.map((data, i) => (
                            <Col md={3}>
                                <Card onClick={() => this.detailProduct(data.kriteria, data.slug)} style={{cursor: "pointer"}}
                                      className='shadow-sm p-0 mb-2 bg-body rounded'>
                                    <ImageLoader className={'card-img-top card-fit'}
                                                 path={`https://is3.cloudhost.id/akp-storage/kategoriproduk/${data.image}`}
                                                 altName={data.image}/>
                                    <Card.Body>
                                        <Card.Title className='display-10 text-capitalize'>{data.nama_kategori}</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section>
        )
    }
}

function WithNavigate(props) {
    let params = useParams();
    let navigate = useNavigate();
    return <KriteriaProduk {...props} params={params} navigate={navigate}/>
}


export default WithNavigate;
