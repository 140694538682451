import React, { Component } from 'react';
import { Container} from 'react-bootstrap';
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import ReactTimeAgo from 'react-time-ago'
class Kinerjabanklist extends Component {
   state = {
      data: []
   }

   componentDidMount() {
      axios.get(`api/kinerja/${this.props.params.slugcategory}`)
         .then(res => {
            const data = res.data;
            this.setState({ data: data });
         })
         .catch(console.error)
   }

   category(slugcategory) {
      // this.props.navigate(`/news/${slug}`);
      window.location.pathname = `/kinerja-bank/${this.props.params.slugcategory}/${slugcategory}`;
   }

   render() {
      document.title = `Bank AKP - ${this.props.params.slugcategory.replace(/-/g, " ")}`;
      return (
         <section className='mt-3 mb-3'>
            <Container>
               <h3 className='display-7 text-capitalize mb-3'>{this.props.params.slugcategory.replace(/-/g, " ")}</h3>
               {this.state.data.map((data, i) => (
                  <div className="card mb-3" onClick={() => this.category(data.slug)} style={{ cursor: "pointer" }}>
                     <div className="row no-gutters">
                        <div className="col-md-4">
                           <img src="https://cdn-icons-png.flaticon.com/512/337/337946.png" alt={data.title} style={{ padding: '10px', width: '120px' }} />
                        </div>
                        <div className="col-md-8">
                           <div className="card-body">
                              <h5 className="card-title">{data.title}</h5>
                              <p className="card-text"><small className="text-muted"><ReactTimeAgo date={data.created_at} locale="en-US"/></small></p>
                           </div>
                        </div>
                     </div>
                  </div>
               ))}
            </Container>
         </section>
      );
   }
}

function WithNavigate(props) {
   let params = useParams();
   let navigate = useNavigate();
   return <Kinerjabanklist {...props} params={params} navigate={navigate} />
}


export default WithNavigate;