import React, { Component } from 'react'
import { Card, Row, Col, Container } from 'react-bootstrap';
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
class Kinerjabank extends Component {
   state = {
      data: []
   }

   componentDidMount() {
      axios.get(`api/kinerja`)
         .then(res => {
            const data = res.data;
            this.setState({ data: data });
         })
         .catch(console.error)
   }

   category(slugcategory) {
      // this.props.navigate(`/news/${slug}`);
      window.location.pathname = `/kinerja-bank/${slugcategory}`;
   }

   render() {
      document.title = `Bank AKP - Kinerja Bank`;
      return (
         <section className='mt-3 mb-3'>
            <Container>
               <h3 className='display-6 text-capitalize'>Kinerja Bank</h3>
               <Row>
                  {this.state.data.map((data, i) => (
                     <Col md>
                        <Card onClick={() => this.category(data.slug)} style={{ cursor: "pointer" }} className='shadow-sm p-3 mb-5 bg-body rounded'>
                           <Card.Img variant="top" className='card-fit' src={`https://is3.cloudhost.id/akp-storage/kategorikinerja/${data.image}`} />
                           <Card.Body>
                              <Card.Title className='display-10 text-capitalize'>{data.category_name}</Card.Title>
                           </Card.Body>
                        </Card>
                     </Col>
                  ))}
               </Row>
            </Container >
         </section>
      );
   }
}

function WithNavigate(props) {
   let params = useParams();
   let navigate = useNavigate();
   return <Kinerjabank {...props} params={params} navigate={navigate} />
}


export default WithNavigate;

