import React from 'react'
import {
    Outlet
} from "react-router-dom";
import ResponsiveAppBar from './ResponsiveAppBar ';
import FooterBottom from './FooterBottom';
import {Container, Link} from 'react-floating-action-button'
import {WhatsApp} from '@mui/icons-material';

export default function Layout() {
    return (
        <>
            <ResponsiveAppBar/>
            <section className='bg-light'>
                <Outlet/>
            </section>
            <FooterBottom/>
            <Container>
                <Link styles={{backgroundColor: '#25D366', color: 'white'}}
                      href="https://api.whatsapp.com/send?phone=6282299909958&text=Halo%20Bank%20AKP" target={'_blank"'}
                      tooltip="Hubungi Kami"><WhatsApp/></Link>
            </Container>
        </>
    )
}
