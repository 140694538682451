import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import { CalendarToday, ArrowRight } from '@mui/icons-material';
import Promo from './Promo';
import { useParams, useNavigate } from "react-router-dom";
import parse from 'html-react-parser';
import ImageLoader from "./ImageLoader";
class DetailPromo extends Component {
   state = {
      data: {},
      desc: ''
   }

   componentDidMount() {
      axios.get(`api/promos/${this.props.params.slug}`)
         .then(res => {
            const data = res.data;
            this.setState({
               data: data,
               desc: data.description,
               date: data.created_at
            });
         })
         .catch(console.error)
   }

   direct(url) {
      window.location.pathname = url;
   }

   render() {
      document.title = `Bank AKP - ${this.state.data.title}`;
      return (
         <>
            <section className='mt-3 mb-3'>
               <Container>
                  <small className="text-muted mb-3"><CalendarToday style={{ fontSize: '13pt', marginBottom: '4px', color: '#3d3838' }} /> {this.state.data.created_at} | Promo</small>
                  <h4 className='text-wrap'>
                     <b>{this.state.data.title}</b>
                  </h4>
                  {/*<img src={`https://is3.cloudhost.id/akp-storage/promos/${this.state.data.image}`} className="img-thumbnail img-fluid rounded mx-auto d-block mt-3 mb-3" alt="promo"></img>*/}
                  <ImageLoader className={'img-thumbnail img-fluid rounded mx-auto d-block mt-3 mb-3'}
                               path={`https://is3.cloudhost.id/akp-storage/promos/${this.state.data.image}`}
                               altName={this.state.data.image}/>
                  <div>
                     {parse(this.state.desc)}
                  </div>
               </Container>
            </section>

            <section>
               <Container>
                  <Row>
                     <Col className='mt-3'><h4><b>Promo Lainnya</b></h4></Col>
                     <Col className='mt-3'>
                        <div onClick={() => this.direct('/promo')} className='link-custom float-end text-primary' style={{ cursor: "pointer" }}>Lihat Semua Promo  <ArrowRight />
                        </div>
                     </Col>
                  </Row>
                  <Promo />
               </Container>
            </section>
         </>
      )
   }
}

function Detailpromo(props) {
   let params = useParams();
   let navigate = useNavigate();
   return <DetailPromo {...props} params={params} navigate={navigate} />
}

export default Detailpromo;