import React, { Component } from 'react'
import { Container } from 'react-bootstrap';
export default class Lokasi extends Component {
   render() {
      return (
         <>
            <Container>
               <h3 className='display-6 text-capitalize'>Lokasi</h3>
               <iframe title="lokasi" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.665141929037!2d117.14682181475337!3d-0.5020664996337033!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2df67fa0048097d5%3A0x60f2bd98eda554ab!2sBPR%20Artha%20Karya%20Perdana!5e0!3m2!1sen!2sid!4v1670202581134!5m2!1sen!2sid" width={'100%'} height="500" style={{ border: '1' }} allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className='mb-3'></iframe>
            </Container>
         </>
      )
   }
}
