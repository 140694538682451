import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import parse from 'html-react-parser';
import axios from 'axios';
export default class TentangKami extends Component {

   state = {
      data: [],
      desc: ''
   }

   componentDidMount() {
      axios.get(`api/tentang-kami`)
         .then(res => {
            const data = res.data;
            this.setState({
               data: data,
               desc: data.description
            });
         })
         .catch(console.error)
   }

   render() {
      document.title = `Bank AKP - Tentang Kami`;
      return (
         <div>
            <section>
               <div className="text-center mb-3">
                  <img src={`https://is3.cloudhost.id/akp-storage/assets/tentangkami/${this.state.data.image}`} className="img-fluid" alt={this.state.data.image}></img>
               </div>
            </section>

            <section className='mt-3 mb-3'>
               <Container className='text-sm-start'>
                  {parse(this.state.desc)}
               </Container>
            </section>
         </div>
      )
   }
}
