import React, { Component } from 'react'
import { Card } from 'react-bootstrap';
import Carousel from "react-multi-carousel";
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import "react-multi-carousel/lib/styles.css";

class RelatedProduct extends Component {
   state = {
      related: []
   }

   responsive = {
      superLargeDesktop: {
         // the naming can be any, depends on you.
         breakpoint: { max: 4000, min: 3000 },
         items: 5
      },
      desktop: {
         breakpoint: { max: 3000, min: 1024 },
         items: 3
      },
      tablet: {
         breakpoint: { max: 1024, min: 464 },
         items: 2
      },
      mobile: {
         breakpoint: { max: 464, min: 0 },
         items: 1
      }
   };

   componentDidMount() {
      axios.get(`api/produk/realated/${this.props.params.kriteria}/${this.props.params.slug}/${this.props.params.slugproduct}`)
         .then(res => {
            const data = res.data;
            this.setState({
               related: data,
               DataisLoaded: true
            });
         })
         .catch(console.error)
   }

   direct(url) {
      window.location.pathname = url;
   }


   render() {
      return (
         <Carousel
            swipeable={true}
            draggable={true}
            showDots={false}
            responsive={this.responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-10-px"
         // centerMode={true}
         >
            {this.state.related.map((data, i) => (
               <div style={{ marginBottom: '5px', marginRight: '10px' }}>
                  <Card onClick={() => this.direct(`kriteria/${this.props.params.kriteria}/${this.props.params.slug}/${data.slug}/`)} style={{ cursor: "pointer" }}>
                     <Card.Img variant="top" className='card-fit' src={`https://is3.cloudhost.id/akp-storage/produk/${data.image}`} />
                     <Card.Body>
                        <Card.Title>{data.nama_produk}</Card.Title>
                        <Card.Text className='text-muted'>
                           {data.subtitle}
                        </Card.Text>
                     </Card.Body>
                  </Card>
               </div>
            ))}
         </Carousel>
      )
   }
}


function WithNavigate(props) {
   let params = useParams();
   let navigate = useNavigate();
   return <RelatedProduct {...props} params={params} navigate={navigate} />
}


export default WithNavigate;