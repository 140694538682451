import React, { Component } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useParams, useNavigate } from "react-router-dom";
import parse from 'html-react-parser';
import axios from 'axios';
import ReactTimeAgo from 'react-time-ago'
import LazyLoad from 'react-lazyload';
class ListNews extends Component {

   state = {
      news: []
   }

   componentDidMount() {
      axios.get(`api/news`)
         .then(res => {
            const data = res.data;
            this.setState({ news: data });
         })
         .catch(console.error)
   }

   detailNews(slug) {
      this.props.navigate(`/news/${slug}`);
   }

   render() {
       document.title = `Bank AKP - News`;
      return (
         <section className='mt-3 mb-3'>
            <Container>
               <h1 className='display-6 text-capitalize'>Berita</h1>
               <Row>
                  {this.state.news.map((data, i) => (
                     <Col md={12}>
                        <LazyLoad height={200}>
                           <Card className='mb-3' onClick={() => this.detailNews(data.slug)} style={{ cursor: "pointer" }}>
                              <Row className='g-0'>
                                 <Col md={6}>
                                    <img className='image-fit rounded-start' src={`https://is3.cloudhost.id/akp-storage/news/${data.image}`} alt="news" />
                                 </Col>
                                 <Col md={6}>
                                    <Card.Body>
                                       <Card.Title className='fw-bold'>{data.title}</Card.Title>
                                       <Card.Text className='fw-normal'>
                                          {parse(data.description.substring(0, 180))} Selengkapnya....
                                       </Card.Text>
                                       <small className="text-muted">Last updated <ReactTimeAgo date={data.created_at} locale="en-US" /></small>
                                    </Card.Body>
                                 </Col>
                              </Row>
                           </Card>
                        </LazyLoad>
                     </Col>

                  ))}
               </Row>
            </Container>
         </section>
      )
   }
}

function WithNavigate(props) {
   let params = useParams();
   let navigate = useNavigate();
   return <ListNews {...props} params={params} navigate={navigate} />
}


export default WithNavigate;