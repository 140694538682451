import React, { Component } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
class ListPromo extends Component {
   constructor(props) {
      super(props);

      this.state = {
         data: []
      };
   }

   detailProduct(kriteria, slug, slugproduct) {
      this.props.navigate(`/kriteria/${kriteria}/${slug}/${slugproduct}`);
   }

   componentDidMount() {
      axios.get(`api/promos`)
         .then(res => {
            const data = res.data;
            this.setState({
               data: data
            });
         })
         .catch(console.error)
   }

   direct(url) {
      window.location.pathname = url;
   }

   render() {
      document.title = `Bank AKP - Promo`;
      return (
         <>
            <section className='mt-3 mb-3'>
               <Container>
                  <h1 className='display-6 text-capitalize'>Promo</h1>
                  <Row>
                     {this.state.data.map((data, i) => (
                        <Col md="3" className="mb-3">
                           <Card className='mb-3' onClick={() => this.direct(`/promo/${data.slug}`)} style={{ cursor: "pointer" }}>
                              <Card.Img className='card-fit' src={`https://is3.cloudhost.id/akp-storage/promos/${data.image}`} />
                              <Card.Body>
                                 <Card.Title>{data.title}</Card.Title>
                              </Card.Body>
                           </Card>
                        </Col>
                     ))}
                  </Row>
               </Container>
            </section>
         </>
      )
   }
}

function WithNavigate(props) {
   let params = useParams();
   let navigate = useNavigate();
   return <ListPromo {...props} params={params} navigate={navigate} />
}


export default WithNavigate;
