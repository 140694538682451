import React, { Component } from 'react'
import { Card } from 'react-bootstrap';
import Carousel from "react-multi-carousel";
import { useParams, useNavigate } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import axios from 'axios';
import ReactTimeAgo from 'react-time-ago'
import ImageLoader from "./ImageLoader";
class RelatedNews extends Component {

   state = {
      news: []
   }

   responsive = {
      superLargeDesktop: {
         // the naming can be any, depends on you.
         breakpoint: { max: 4000, min: 3000 },
         items: 3
      },
      desktop: {
         breakpoint: { max: 3000, min: 1024 },
         items: 3
      },
      tablet: {
         breakpoint: { max: 1024, min: 464 },
         items: 2
      },
      mobile: {
         breakpoint: { max: 464, min: 0 },
         items: 1
      }
   };

   detailNews(slug) {
      // this.props.navigate(`/news/${slug}`);
      window.location.pathname = `/news/${slug}`;
   }

   componentDidMount() {

      axios.get(`api/news/realated/${this.props.params.slug}`)
         .then(res => {
            const data = res.data;
            this.setState({ news: data });
         })
         .catch(console.error)
   }


   render() {
      return (
         <Carousel
            swipeable={true}
            draggable={true}
            showDots={false}
            responsive={this.responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-10-px"
            centerMode={true}
         >
            {this.state.news.slice(0, 5).map((data, i) => (
               <div style={{ marginBottom: '5px', marginRight: '10px' }}>
                  <Card onClick={() => this.detailNews(data.slug)} style={{ cursor: "pointer" }}>
                     <ImageLoader className={'card-img-top card-fit'}
                                  path={`https://is3.cloudhost.id/akp-storage/news/${data.image}`}
                                  altName={data.image}/>
                     <Card.Body>
                        <Card.Title>{data.title}</Card.Title>
                        <small className="text-muted">Last updated <ReactTimeAgo date={data.created_at} locale="en-US" /></small>
                     </Card.Body>
                  </Card>
               </div>
            ))}
         </Carousel>
      )
   }
}

function WithNavigate(props) {
   let params = useParams();
   let navigate = useNavigate();
   return <RelatedNews {...props} params={params} navigate={navigate} />
}


export default WithNavigate;