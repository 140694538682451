import React, { Component } from 'react';
import axios from 'axios';
import { Container } from 'react-bootstrap';
import { CalendarToday } from '@mui/icons-material';
import { useParams, useNavigate } from "react-router-dom";
import parse from 'html-react-parser';

class DetailKarir extends Component {

   state = {
      data: {},
      desc: ''
   }

   componentDidMount() {
      axios.get(`api/karir/${this.props.params.slug}`)
         .then(res => {
            const data = res.data;
            this.setState({
               data: data,
               desc: data.description,
               date: data.created_at
            });
         })
         .catch(console.error)
   }

   render() {
      document.title = `Bank AKP - ${this.state.data.title}`;
      return (
         <>
            <section className='mt-3 mb-3'>
               <Container>
                  <small className="text-muted mb-3"><CalendarToday style={{ fontSize: '13pt', marginBottom: '4px', color: '#3d3838' }} /> {this.state.data.created_at} | Karir</small>
                  <h4 className='text-wrap'>
                     <b>{this.state.data.title}</b>
                  </h4>
                  <img src={`https://is3.cloudhost.id/akp-storage/karir/${this.state.data.image}`} className="img-fluid rounded mx-auto d-block mt-3 mb-3" alt="tentang kami"></img>
                  <div>
                     {parse(this.state.desc)}
                  </div>
               </Container>
            </section>
         </>
      )
   }
}

function WithNavigate(props) {
   let params = useParams();
   let navigate = useNavigate();
   return <DetailKarir {...props} params={params} navigate={navigate} />
}

export default WithNavigate;