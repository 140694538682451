import './App.css';
import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from './components/Layout';
import Home from './components/pages/Home';
import KriteriaProduk from './components/pages/KriteriaProduk';
import Products from './components/pages/Products';
import Product from './components/pages/Product';
import TentangKami from './components/pages/TentangKami';
import DetailNews from './components/pages/DetailNews';
import DetailPromo from './components/pages/DetailPromo';
import Kinerjabank from './components/pages/Kinerjabank';
import Kinerjabanklist from './components/pages/Kinerjabanklist';
import Kinerjadetail from './components/pages/Kinerjadetail';
import ListNews from './components/pages/ListNews';
import ListPromo from './components/pages/ListPromo';
import ListKarir from './components/pages/karir/ListKarir';
import DetailKarir from './components/pages/karir/DetailKarir';
import Lokasi from './components/pages/Lokasi';
import Page404 from './components/pages/Page404'
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />} >
          <Route path="/" element={<Home />} />
          <Route path="/kriteria/:kriteria" element={<KriteriaProduk />} />
          <Route path="/kriteria/:kriteria/:slug" element={<Products />} />
          <Route path="/kriteria/:kriteria/:slug/:slugproduct" element={<Product />} />
          <Route path="/promo" element={<ListPromo />} />
          <Route path="/promo/:slug" element={<DetailPromo />} />
          <Route path="/tentang-kami" element={<TentangKami />} />
          <Route path="/news" element={<ListNews />} />
          <Route path="/news/:slug" element={<DetailNews />} />
          <Route path="/kinerja-bank" element={<Kinerjabank />} />
          <Route path="/kinerja-bank/:slugcategory" element={<Kinerjabanklist />} />
          <Route path="/kinerja-bank/:slugcategory/:slug" element={<Kinerjadetail />} />
          <Route path="/karir" element={<ListKarir />} />
          <Route path="/karir/:slug" element={<DetailKarir />} />
          <Route path="/lokasi" element={<Lokasi />} />
          <Route path="*" component={Page404} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
