import React, {useState, useEffect} from 'react';
import SpinnerComponent from './SpinnerComponent';
import {Img} from 'react-image';
const ImageLoader = ({className, path, altName}) => {
    const [loading, setLoading] = useState(true);
    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
        const loadImage = () => {
            const img = new Image();
            const imageSrc = path; // Ganti dengan path ke gambar Anda

            img.onload = () => {
                // Gambar telah dimuat, atur state loading menjadi false
                setImageUrl(imageSrc);
                setLoading(false);
            };

            img.src = imageSrc;
        };

        loadImage();
    }, ); // Efek hanya dipanggil saat komponen pertama kali dimuat

    return (
        <div>
            {loading ? (
                <SpinnerComponent/> // Tampilkan spinner jika loading true
            ) : (
                <Img className={className} src={imageUrl} alt={altName}/> // Tampilkan gambar setelah dimuat
            )}
        </div>
    );
};

export default ImageLoader;
