import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import { CalendarToday } from '@mui/icons-material';
class Kinerjadetail extends Component {

   state = {
      data: [],
      pageNumber: 1,
      numPages: 0,
   }

   componentDidMount() {
      axios.get(`api/kinerja/${this.props.params.slugcategory}/${this.props.params.slug}`)
         .then(res => {
            const data = res.data;
            this.setState({ data: data });
         })
         .catch(console.error)
   }

   onDocumentLoadSuccess({ numPages }) {
      this.setState({ numPages: numPages });
   }

   render() {
      document.title = `Bank AKP - ${this.state.data.title}`;
      return (
         <section className='mt-3 mb-3'>
            <Container>
               <h3 className='display-8 text-capitalize mb-3'>{this.state.data.title}</h3>
               
               <small className="text-muted mb-3"><CalendarToday style={{ fontSize: '13pt', marginBottom: '4px', color: '#3d3838' }} /> {this.state.data.created_at}</small>

               <iframe title="laporan" src={`https://is3.cloudhost.id/akp-storage/kinerja/${this.state.data.filename}`} style={{ overflow: 'hidden', width: '100%' }} height="1000" frameborder="0"></iframe>
            </Container>
         </section >
      );
   }
}

function WithNavigate(props) {
   let params = useParams();
   let navigate = useNavigate();
   return <Kinerjadetail {...props} params={params} navigate={navigate} />
}


export default WithNavigate;